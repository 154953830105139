<template>
  <div class="wrapper">
    <AppHeader />
    <Suspense>
      <template #default>
        <Videos :id="id" />
      </template>
      <template #fallback>
        <Loading />
      </template>
    </Suspense>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader.vue";
import AppFooter from "@/components/layouts/AppFooter.vue";
import { defineAsyncComponent, Suspense } from "vue";
import Loading from "@/components/Spinners/Loading.vue";
// import Videos from "@/components/Pages/Videos.vue";
const Videos = defineAsyncComponent(() =>
  import('@/components/Pages/Videos.vue')
)
export default {
  name: "VideoPlay",
  props: {
    id: String,
  },
  components: {
    AppHeader,
    AppFooter,
    Videos,
    Loading,
    Suspense,
  },
};
</script>

<style>
</style>